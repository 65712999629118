import type { BrandShortNames } from "ui/types";
import type {
  BetBuilderBrunoEventData,
  Bookmaker,
  BookmakerForTip,
  Events,
  MatchData,
  StatsTipsterStats,
} from "ui/types/api/tipster";
import { fpCompetitionSportsMap } from "ui/constants/competitions";
import type { TipOdds } from "ui/types/api/odds";
import type { Last5Results, PredictionResult } from "ui/types/api/predictions";

export const validTipsterSignUpLink = (
  brand: BrandShortNames,
  bookmakerData: BookmakerForTip | undefined,
  locale: string
): string | undefined => {
  // Immediately return if no bookmaker data
  if (!bookmakerData) return undefined;

  // Check there are links
  const links =
    bookmakerData.links && bookmakerData.links.length > 0
      ? bookmakerData.links[0]
      : undefined;

  // Return if no links
  if (!links) return undefined;

  // Check for localised links
  const localisedLinks = links[`${brand}_locations`];

  // Return the localised link if it exists
  if (localisedLinks?.[locale]) {
    return localisedLinks[locale];
  }

  // Return the default link if it exists
  return links[brand] ?? undefined;
};

export const normalizeToDecimal = (decimalValue: string | number): number => {
  return typeof decimalValue === "string"
    ? parseFloat(decimalValue)
    : decimalValue;
};

export const getBestOddsFromBookmakers = (
  bookmakers: Bookmaker[]
): Bookmaker[] =>
  bookmakers.slice().sort(
    (a, b) =>
      // Descending order
      normalizeToDecimal(b.oddsDecimal) - normalizeToDecimal(a.oddsDecimal)
  );

interface CreateTeamDetailsProps {
  match: MatchData | BetBuilderBrunoEventData;
  competition?: string;
  form?: Last5Results;
}

export const createTeamDetails = ({
  match,
  competition,
  form,
}: CreateTeamDetailsProps): {
  name: string;
  badge: string;
  form: PredictionResult[] | undefined;
}[] => {
  const {
    team_a_badge: teamABadge,
    team_a_name: teamAName,
    team_b_badge: teamBBadge,
    team_b_name: teamBName,
  } = match;

  if (competition && fpCompetitionSportsMap.has(competition)) {
    return [
      {
        name: teamBName,
        badge: teamBBadge,
        form: form?.away,
      },
      {
        name: teamAName,
        badge: teamABadge,
        form: form?.home,
      },
    ];
  }

  return [
    {
      name: teamAName,
      badge: teamABadge,
      form: form?.home,
    },
    {
      name: teamBName,
      badge: teamBBadge,
      form: form?.away,
    },
  ];
};

export const isValidStats = (
  statsTipsterStats: StatsTipsterStats,
  statsForGame: Events
): boolean => {
  return statsTipsterStats.type !== "no-stats" && statsForGame.stats.length > 1;
};

export const bookmakersToDisplay = (
  odds: TipOdds[],
  viewMore: boolean,
  isDesktop: boolean
): TipOdds[] => {
  if (viewMore) return odds;

  if (!isDesktop) return odds.slice(0, 2);

  return odds.slice(0, 4);
};
