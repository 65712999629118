import { fullDateToShortDateWithAMorPM } from "ui/utils/date/date";
import type { BetBuilderBrunoEventData } from "ui/types/api/tipster";
import { fpCompetitionSportsMap } from "ui/constants/competitions";
import { createTeamDetails } from "../utils/tipsterUtils";
import { BetBuilderTipsterTeamRow } from "./BetBuilderTipsterTeamRow";
import styles from "./BetBuilderTipsterBrunoMatchDetails.module.scss";

interface BetBuilderTipsterBrunoMatchDetailsProps {
  brunoEventData: BetBuilderBrunoEventData;
}

export function BetBuilderTipsterBrunoMatchDetails({
  brunoEventData,
}: BetBuilderTipsterBrunoMatchDetailsProps): React.JSX.Element {
  const {
    competition_name: competitionName,
    competition_id: competitionId,
    date_iso: dateIso,
  } = brunoEventData;
  const isUsSports = fpCompetitionSportsMap.has(brunoEventData.competition_id);
  const [homeTeam, awayTeam] = createTeamDetails({
    match: brunoEventData,
    competition: brunoEventData.competition_id,
  });

  return (
    <>
      <span className={styles.competitionInfo}>
        {/* competition_name only exists in non-US sports data */}
        {competitionName ? competitionName : competitionId} •{" "}
        {fullDateToShortDateWithAMorPM(dateIso)}
      </span>
      <div className={styles.teamInfo}>
        <BetBuilderTipsterTeamRow
          teamBadgeUrl={homeTeam.badge}
          teamName={homeTeam.name}
        />
        <div className={styles.divider}>
          <span>{isUsSports ? "@" : "VS"}</span>
          <hr />
        </div>
        <BetBuilderTipsterTeamRow
          teamBadgeUrl={awayTeam.badge}
          teamName={awayTeam.name}
        />
      </div>
    </>
  );
}
