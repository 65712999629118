import { useState } from "react";
import Image from "next/image";
import { fullDateToSplitShortDate } from "ui/utils/date/date";
import { getSupportedCompetitionSlugForBrandFromName } from "ui/utils/competitions/competitions";
import { Editor } from "ui/features/Editor";
import { codeBlockToHtml } from "ui/utils/draftManager/draftManager";
import { LinkNoPrefetch } from "ui/components/LinkNoPrefetch/LinkNoPrefetch";
import { FormIcons } from "ui/components/FormIcons/FormIcons";
import { FormIcon } from "ui/components/icons/FormIcon";
import { createTeamDetails, isValidStats } from "../utils/tipsterUtils";
import type { Events, GridData } from "../../../types/api/tipster";
import { Stats } from "./Stats";
import styles from "./StatsTip.module.scss";

interface StatsTipProps {
  matchDetails: GridData;
  statsForGame: Events;
}

export function StatsTip({
  matchDetails,
  statsForGame,
}: StatsTipProps): React.JSX.Element | null {
  const [showStats, setShowStats] = useState(false);

  const { match, stats, reason } = matchDetails;

  if (!match) return null;

  const { event, market, selection } = statsForGame;
  const { name, id: competitionId } = event.meta.competition;
  const { form, date } = event;

  const splitShortDate = fullDateToSplitShortDate(date);
  const { writtenDate, time } = splitShortDate;

  // US sports API data only returns the competitionId key, not name
  // So we use this if we don't have a name key
  const competitionName = name ?? competitionId;

  const supportedCompetitionSlug =
    getSupportedCompetitionSlugForBrandFromName(competitionName);

  const [homeTeam, awayTeam] = createTeamDetails({
    match,
    competition: competitionName,
    form,
  });

  return (
    <div className={styles.statsTip} data-testid="statsTip">
      <div className={styles.competitionNameContainer}>
        <h5 className={styles.competitionName}>
          {supportedCompetitionSlug ? (
            <LinkNoPrefetch
              className={styles.competitionName}
              href={`/news/${supportedCompetitionSlug}`}
            >
              {competitionName}
            </LinkNoPrefetch>
          ) : (
            competitionName
          )}
        </h5>
      </div>
      <div className={styles.matchInformationContainer}>
        <div className={styles.teamContainer}>
          <div className={styles.team}>
            <Image
              alt={homeTeam.name}
              className={styles.teamBadge}
              height={20}
              src={homeTeam.badge}
              width={20}
            />
            <div>{homeTeam.name}</div>
            <span className={styles.time} suppressHydrationWarning>
              {time}
            </span>
            {form && homeTeam.form && homeTeam.form.length > 0 ? (
              <div className={styles.form} data-testid="homeForm">
                <FormIcons
                  enlargeForLaptops={false}
                  form={homeTeam.form}
                  height="6px"
                  heightForLastMatch="10px"
                  margin="0px 4px 0px 0px"
                  width="6px"
                  widthForLastMatch="10px"
                />
              </div>
            ) : (
              <div className={styles.singleTeamForm} />
            )}
          </div>
          <div className={styles.team}>
            <Image
              alt={awayTeam.name}
              className={styles.teamBadge}
              height={20}
              src={awayTeam.badge}
              width={20}
            />
            <div>{awayTeam.name}</div>
            <span className={styles.time}>{writtenDate}</span>
            {form && awayTeam.form && awayTeam.form.length > 0 ? (
              <div className={styles.form} data-testid="awayForm">
                <FormIcons
                  enlargeForLaptops={false}
                  form={awayTeam.form}
                  height="6px"
                  heightForLastMatch="10px"
                  margin="0px 4px 0px 0px"
                  width="6px"
                  widthForLastMatch="10px"
                />
              </div>
            ) : (
              <div className={styles.singleTeamForm} />
            )}
          </div>
        </div>
        {stats && isValidStats(stats, statsForGame) ? (
          <>
            <br />
            <div className={styles.statsButtonContainer}>
              <button
                aria-pressed={showStats}
                className={styles.statsButton}
                onClick={() => {
                  setShowStats(!showStats);
                }}
                type="button"
              >
                <FormIcon
                  formIconClassName={styles.formIcon}
                  formIconContainerClassName={styles.formIconContainer}
                />
                <span>Stats</span>
              </button>
            </div>
          </>
        ) : null}
      </div>
      <div className={styles.reasonContainer}>
        <div
          className={styles.market}
        >{`${market.name} - ${selection.name}`}</div>
        {reason ? (
          <div className={styles.reason}>
            <Editor formattedContent={codeBlockToHtml(reason)} removePadding />
          </div>
        ) : null}
      </div>
      {showStats && stats?.type ? (
        <div className={styles.statsContainer}>
          <Stats
            selection={stats.selection}
            statsType={stats.type}
            tipsterStats={statsForGame}
          />
        </div>
      ) : null}
    </div>
  );
}
