import { useContext } from "react";
import { CardWrapper } from "ui/features/CardWrapper";
import { getActiveBrand } from "ui/utils/brand/brand";
import {
  getCurrentUnixTimestampMs,
  unixTimestampToDateMonthYearHourAndMins,
} from "ui/utils/date/date";
import { getFormattedOdds } from "ui/utils/odds/odds";
import { FRACTIONAL_ODDS_FORMAT } from "ui/constants/odds";
import { useLocaleFromRouter } from "ui/hooks/LocationFromRouter";
import type { TipsterData } from "ui/types/api/tipster";
import { BetSlipStateContext } from "../../../contexts/betSlipContext";
import { CustomTipCta } from "../../../components/CustomTipCta/CustomTipCta";
import { validTipsterSignUpLink } from "../utils/tipsterUtils";
import styles from "./Tipster.module.scss";
import { CustomTip } from "./CustomTip";
import { BetBuilderTipster } from "./BetBuilderTipster";
import { StatsTipster } from "./StatsTipster";

export interface TipsterProps {
  tipsterData: TipsterData;
  tipCategoryId: string;
}

export function Tipster({
  tipsterData,
  tipCategoryId,
}: TipsterProps): React.JSX.Element {
  const [locale] = useLocaleFromRouter();
  const betSlipContext = useContext(BetSlipStateContext);
  const oddsFormat = betSlipContext?.oddsFormat ?? FRACTIONAL_ODDS_FORMAT;
  const { bookmakers, tips = [] } = tipsterData;
  const brand = getActiveBrand();

  const allTipsExpired = tips.every((tip) => {
    return tip.expires_at < getCurrentUnixTimestampMs();
  });

  if (tips.length === 0 || allTipsExpired) {
    return (
      <div className={styles.noTips}>Sorry, no tips are available today!</div>
    );
  }

  return (
    <>
      {
        // eslint-disable-next-line array-callback-return -- legacy code
        tips.map((tip, index) => {
          const { meta, updated_at: updatedAt, outcomes, category } = tip;
          const { grid, type } = meta;

          if (tipCategoryId === category) {
            switch (type) {
              case "custom": {
                return bookmakers.length > 0 && grid.length > 0 && updatedAt ? (
                  <CardWrapper
                    date={unixTimestampToDateMonthYearHourAndMins(updatedAt)}
                    key={index}
                    listHeader={meta.title}
                    listSubheader="*Odds Available as of"
                  >
                    <>
                      {grid.map(
                        (
                          { entity_name: entityName, tip: customTip, reason },
                          gridIndex
                        ) => (
                          <CustomTip
                            key={gridIndex}
                            reason={reason}
                            tip={customTip ?? "Undefined tip"}
                            title={entityName ?? ""}
                          />
                        )
                      )}
                      {meta.bookmakers.map(
                        (
                          { odds, oddsAmerican, oddsDecimal, url, bookmaker },
                          bookmakerIndex
                        ) => {
                          const bookmakerData = bookmakers.find(
                            (data) => data._id === bookmaker
                          );

                          return (
                            <CustomTipCta
                              bookmaker={
                                bookmakerData
                                  ? bookmakerData.name
                                  : bookmakers[0].name
                              }
                              ctaUrl={url}
                              key={bookmakerIndex}
                              odds={getFormattedOdds(
                                { odds: `${odds}`, oddsAmerican, oddsDecimal },
                                oddsFormat
                              )}
                              oddsFormat={oddsFormat}
                              signUpUrl={validTipsterSignUpLink(
                                brand,
                                bookmakerData,
                                locale
                              )}
                            />
                          );
                        }
                      )}
                    </>
                  </CardWrapper>
                ) : null;
              }
              case "bet-builder": {
                return bookmakers.length > 0 &&
                  grid.length > 0 &&
                  updatedAt &&
                  meta.event ? (
                  <BetBuilderTipster
                    bookmakers={bookmakers}
                    key={index}
                    oddsFormat={oddsFormat}
                    tip={tip}
                  />
                ) : null;
              }
              case "checkd-stats": {
                return bookmakers.length > 0 &&
                  grid.length > 0 &&
                  updatedAt &&
                  outcomes ? (
                  <StatsTipster
                    bookmakers={bookmakers}
                    key={index}
                    oddsFormat={oddsFormat}
                    tip={tip}
                  />
                ) : null;
              }
            }
          }
        })
      }
    </>
  );
}
