import React from "react";
import Image from "next/image";
import styles from "./BetBuilderTipsterTeamRow.module.scss";

interface BetBuilderTipsterTeamRowProps {
  teamName: string;
  teamBadgeUrl: string;
}

export function BetBuilderTipsterTeamRow({
  teamName,
  teamBadgeUrl,
}: BetBuilderTipsterTeamRowProps): React.JSX.Element {
  return (
    <div>
      <div className={styles.teamBadgeImage}>
        <Image
          alt={`${teamName} team badge`}
          height={24}
          src={teamBadgeUrl}
          width={24}
        />
      </div>
      <span>{teamName}</span>
    </div>
  );
}
